*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card{
  border-radius: 5px;
  display: grid;
  width: 36rem;
  padding-right: 2rem;
  height: 15rem;
  float: left;
  padding: 2rem;
  background-color: #3F3F3F;
  transition: 0.2s;
}



.card h1{
  font-weight: 400;
  margin-left: 0;
  grid-row: 1/2;
  text-align: left;
  font-size: 24px;
}

.card h2{
  font-weight: 300;
  margin-left: 0;
  grid-row: 2/3;
  text-align: left;
  font-size: 18px;
  color: #acacac;
}

.card h3{
  margin-left: 0;
  font-weight: 400;
  grid-row: 3/4;
  margin-top: auto;
  text-align: left;
  font-size: 24px;
  color: #fde07f;
}

.card p{
  color: #777673;
}


.cardB{
  transition: 0.2s;
  border-radius: 5px;
  display: grid;
  width: 36rem;
  padding-right: 2rem;
  height: 15rem;
  float: left;
  padding: 2rem;
  background-color: #646464;
}

.cardB h1{
  font-weight: 400;
  margin-left: 0;
  grid-row: 1/2;
  text-align: left;
  font-size: 24px;
}

.cardB h2{
  font-weight: 300;
  margin-left: 0;
  grid-row: 2/3;
  text-align: left;
  font-size: 18px;
  color: #bfbfbf;
}

.cardB h3{
  margin-left: 0;
  font-weight: 400;
  grid-row: 3/4;
  margin-top: auto;
  text-align: left;
  font-size: 24px;
  color: #fde07f;
}




.card:hover{
  background-color: #313131;
}

.cardB:hover{
  background-color: #5f5f5f;
}



/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .card{
        padding: 2rem;
        width: 100%;
        min-height: 15rem;
        max-height: 18rem;
       }
  
       .card h3{
        font-size: 18px;
       }
  
       .card h1{
        margin-top: 0;
      }
  
      .cardB h2{
        max-width: 600px;
      }
  
      .card h2{
        max-width: 600px;
      }
  
       .cardB h3{
        font-size: 18px;
      }
  
      .cardB h1{
        margin-top: 0;
      }
  
       .cardB{
        padding: 2rem;
        width: 100%;
        min-height: 15rem;
        height: auto;
       }
      
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
     .card{
      padding: 2rem;
      width: 100%;
      height: 12rem;
     }

     .card h3{
      font-size: 18px;
     }

     .card h1{
      margin-top: 0;
    }

    .cardB h2{
      max-width: 600px;
    }

    .card h2{
      max-width: 600px;
    }

     .cardB h3{
      font-size: 18px;
    }

    .cardB h1{
      margin-top: 0;
    }

     .cardB{
      padding: 2rem;
      width: 100%;
      height: 12rem;
     }

    }
    
/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 1250px) {
  
  }
  