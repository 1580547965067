.button {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #E5E5E5;
  background: #3F3F3F;
  border-radius: 5px;
  padding: 12px 18px 12px 18px;
  width: min-content;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;


}

.button:hover{
  background-color: #313131;
}

  
  .button svg {
    vertical-align: top;
  }




/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .button{
        margin-left: 1rem;
        font-size: 20px;
      }

      .button svg{
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 2px;
      }
     
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
    
      
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Desktop
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 1250px) {
      
    }
  