a {
  text-decoration: none;
}

.todo {
  overflow: hidden;
  z-index: 0;
  min-width: 100%;
  width: auto;
  max-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background: #181818;
  z-index: 999;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  min-height: 100vh;
  height: auto;
  margin: 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}



.xloader {
  animation: xkloader infinite 2.5s linear;
  border: #FDE07F 20px solid;
  border-radius: 50%;
  box-sizing: border-box;
  height: 80px;
  opacity: 0;
  width: 80px;
}

.decision{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.decision h1{
  font-size: 28px;
}

.decision p{
  max-width: 350px;
  margin-top: 0.2rem;
  text-align: center;
  font-size: 18px;
  transition: 0.5s;
}



@keyframes xkloader {
  0% {
      transform: scale(0);
      opacity: 0;
  }

  20% {
      opacity: 1;
  }

  25% {
      transform: scale(1);
      opacity: 1;
  }

  50% {
      border-width: 0;
      opacity: 0;
      transform: scale(1.3);
  }
}

@media (max-width: 699px) {
  .overlay {
      border-radius: 0;
      width: 100vw;
      height: 100vh;
  }

  @media(max-height: 475px) {}
}

/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {

}

/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 700px) and (max-width: 1250px) {

}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {



}