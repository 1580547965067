.button {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height, or 104% */
    color:#181818;
    background: #DADADA;
    border-radius: 5px;
    padding: 8px 14px 8px 14px;
    width: min-content;
    margin-top: 2rem;
    white-space: nowrap;
    text-decoration: none;
    transition: 0.2s;
  }
  
  .button:hover{
    background: #ededed;
  }

  
  .button svg {
    vertical-align: middle;
    margin-right: 1px;
    margin-bottom: 2px;
  }


/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */
    
    @media (max-width: 699px) {
     
     
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
    
      
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Desktop
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 1250px) {
      
    }
  