* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card {
  border-radius: 5px;
  display: grid;
  width: 50rem;
  padding-right: 2rem;
  height: auto;
  float: left;
  padding: 2rem;
  background-color: #3F3F3F;
  transition: 0.2s;
  grid-template-rows: auto auto;
}

.top {
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-row: 1/2;
  grid-column: 1/3;
}

.right svg {
  margin-bottom: -2px;
}

.right h2,
a {
  font-weight: 450;
}

.right a {
  font-weight: 450;
}

.down {
  grid-row: 2/3;
  grid-column: 1/3;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
  grid-template-rows: auto auto;
}

.left {
  grid-column: 1/2;
}

.ball {
  margin-top: -5px;
  margin-left: -2px;
  width: 15px;
  height: 15px;
  border-radius: 90px;
  background-color: #646464;
  ;
}

.ballB {
  margin-top: -5px;
  margin-left: auto;
  margin-right: -2px;
  width: 15px;
  height: 15px;
  border-radius: 90px;
  background-color: #646464;
  ;
}

.square {
  display: grid;
  grid-template-rows: auto auto;
}

.toptop {
  width: 100%;
  height: 5px;
  margin-top: 2rem;
  background-color: #646464;
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-row: 1/2;
}

.leftleft {
  width: 100%;
  height: 5px;
  grid-column: 1/2;
}

.downdown h1 {
  display: inline;
  color: #777673;
  margin-right: auto;
  text-align: left;
  font-size: 16px;
}

.rightright {
  width: 100%;
  height: 5px;
  grid-column: 2/3;
}


.left h1 {
  text-align: left;
  color: #D9D9D9;
  font-size: 24px;
  margin-top: 0;
  margin-right: auto;
}

.left h2 {
  text-align: left;
  color: #929292;
  font-size: 20px;
  margin-top: 0;
  margin-right: auto;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  width: 100%;
  grid-column: 2/3;
}

.right a {
  color: #D9D9D9;
}

.right h2 {
  display: inline;
  margin-top: 0;
  text-align: right;
  margin-right: 0;
  font-size: 18px;

  color: #929292;
}

.rightright h1 {
  position: absolute;
  display: inline;
  color: #777673;
  margin-top: 12px;
  margin-right: 0;
  margin-left: auto;
  text-align: right;
  font-size: 16px;
}

.order h3 {
  color: #3F3D3D;
  text-align: left;
  font-size: 24px;
  margin-bottom: 0.5rem;
}

.full {
  margin-top: 3rem;
}


.cardB:hover {
  background-color: #3F3F3F;
}

.downdownB h2 {
  display: inline;
  color: #777673;
  font-size: 16px;
}

.downdownB h1 {
  display: inline;
  color: #777673;
  font-size: 16px;
  text-align: right;
}


.downdownB h1:nth-child(1) {
  margin-left: 0;
}

.downdownB h1:nth-child(2) {
  margin-right: 0;
}

.downdownB {
  display: flex;
  align-items: space-between;
}

.downdown{
  margin-top: 0rem;
  display: flex;
  align-items: space-between;

}

.downdown h1{
  margin-left: 0;
}

/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {


  .card {
    padding: 2rem;
    max-width: 30rem;
    width: 100%;

  }

  .top{
    grid-template-columns: 10fr;
  }

  .downdown h1{
    display: none;
  }

  .downdownB h1{
    display: none;
  }



  .right h2{
    font-size: 12px;
  }

  .downdown h1 {
    font-size: 12px;
  }

  .order h3{
    font-size: 18px;
  }

  .downdownB h1 {
    font-size: 12px;
  }

  .left h1 {
    font-size: 18px;
  }
  .left h2 {
    font-size: 12px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
    width: 100%;
    grid-column: 2/3;
  }

  .downdownB h2 {
    margin-left: -27%;
    display: inline;
    color: #777673;

    font-size: 12px;
  }

  .downdownB h3 {
    margin-left: auto;
    margin-right: -15px;
    display: inline;
    color: #777673;

    font-size: 12px;
  }


  .downdown h2 {
    display: inline;
    color: #777673;
    font-size: 12px;
  }

  .downdownB{
    margin-top: 1rem;
  }

  .downdown{
    margin-top: 1rem;
  }


}

/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 700px) and (max-width: 1250px) {
  .card {
    padding: 2rem;
    max-width: 50rem;
    width: 100%;
  }

  .downdown h1 {
    font-size: 12px;
  }

  .downdownB h1 {

    font-size: 12px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
    width: 100%;
    grid-column: 2/3;
  }

  .downdownB h2 {
    display: none;
  }

  .downdownB h1 {
    margin-right: 0;
  }
  .downdown h2 {
   display: none;
  }
  .downdownB h3 {
    display: none;
  }

}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {

  .downdownB h2 {
    display: none;
  }

  .downdownB h3 {
    display: none;
  }

  .downdownB h1 {
    margin-right: 0;
  }

  .downdown h2 {
   display: none;
  }

}