*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card{
  display: block;
  width: 40rem;
  padding-right: 2rem;
  height: 10rem;
  float: left;
  margin-bottom: 2rem;
}

.left{
  float: left;
  padding: 1rem;
  padding-left: 2rem;
  height: 100%;
  width: 60%;
  background-color: #181818;
  border-radius: 5px 0rem 0rem 5px;
  transition: 0.2s;
}


.leftContent{
  text-align: left;
  padding-top: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.left h1{
  font-size: 1.5rem;
  text-align: left;
}

.left h2{
  margin-top: 0.5rem;
  color: #929292;
  font-size: 1rem;
  text-align: left;
}

.left h3{
  margin-top: 0.2rem;
  font-size: 1rem;
  text-align: left;
  font-weight: 300;
}

.right{ 
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 2rem;
  height: 100%;
  width: 40%;
  background-color: #111;
  border-radius: 0rem 5px 5px 0rem;
  transition: 0.2s;
}

.target:hover .left{
  width: 50%;
    background-color: #111111;;
}

.target:hover .right{
  width: 50%;
  background-color: #181818;
}



.logo{
  display: block;
  width: 10rem;


}





/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


    
    @media(max-width: 699px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
        grid-template-rows: 11fr 1fr;
      }

     

      .card{
        display: block;
        width: 100%;
       
        height: 8rem;
        float: left;
        margin-bottom: 1rem;
      }

      .left h1{
        font-size: 1rem;
      }
      
      .left h2{
        font-size: 0.6rem;
     
      }
      
      .left h3{
        font-size: 0.8rem;

      }

      .logo{
        width: 5rem;
      }

    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 3.32fr 3.32fr 3.32fr;
        grid-template-rows: 8fr 2fr;
      }
      /*wrapper Childs*/
      
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Desktop
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 3.32fr 3.32fr 3.32fr;
        grid-template-rows: 8fr 2fr;
      }
      /*wrapper Childs*/
      .topAbout {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }