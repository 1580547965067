a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  min-width: 100%;
  width: auto;
  max-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background: #181818;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  min-height: 100vh;
  height: auto;
  margin: 0 10px 0;

}

.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-top: 6rem;
  top: 0;
  right: 0;
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E5E5E5;
  position: fixed;
  cursor: pointer;
}

.bar input{ 
  text-align: center;
  width: 180px;
  height: 50px;
  font-size: 18px;
  color: #ababab;
  border-color: transparent;
  float: center;
  
  text-decoration: none;
  background-color: transparent;
}

.redirect p {
  transition: 0.2s;
  font-size: 18px;
  color: #3F3D3D;
}

.redirect svg {
  vertical-align: middle;
  margin-bottom: 1px;
}

.header {
  margin-top: 6rem;
}

.header h1 {
  font-size: 38px;
  text-align: left;
}

.sub{
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
}
.search{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  background-color: #cbcbcb;
  border-radius: 5px;
  width: 50px;
  height: 50px;
}

.search p{
  vertical-align: middle;
  text-align: center;
  size: 28px;
}

.search svg{
  margin-bottom: 2px;
  color: #181818;
  vertical-align: middle;
}



.searchOff{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  background-color: #151515;
  border-radius: 5px;
  width: 50px;
  height: 50px;
}

.searchOff p{
  vertical-align: middle;
  text-align: center;
  size: 28px;
}

.error{
  max-width: 350px;
  margin-top: 1rem;
  display: block;
  font-size: 18px;
  color: #ee6e6e;
}

.error a{
  color: #929292;
}

.searchOff svg{
  margin-bottom: 2px;
  color: #2e2e2e;
  vertical-align: middle;
}


.bar{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3F3F3F;
  border-radius: 5px;
  width: 180px;
  height: 50px;
  padding: 1.5rem;
}

.header h2 {
  font-size: 24px;
  max-width: 400px;
  margin-right: auto;
  margin-left: 0;
  text-align: left;
  font-weight: 300;
  color: #929292;
}

.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p {
  color: #3F3F3F;
}


/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    grid-template-rows: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .redirect {
    position: fixed;
    margin-top: 3.5rem;

  }

  .header {
    margin-top: 3.8rem;
  }



  
  .header h1{
    font-size:28px;
  }
  .header h2 {
    font-size: 18px;
    width: 80%;
  }

  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .todo {
    min-height: 100vh;
    height: auto;
    background: #181818;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }
}

/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 700px) and (max-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    grid-template-rows: auto;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .todo {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }
}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    grid-template-rows: auto;
    max-height: 100vh;
  }

  .mobileOnly {
    display: none;
  }

}