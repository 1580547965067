.body {
  background-color: #181818;
}

html {
  scroll-behavior: smooth;
 }

.todo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #181818;

  scroll-behavior: smooth;
}

.notFound img {
  width: 100%;
  height: 50%;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  height: 100vh;
  margin: 0 10px 0;
  display: grid;
}

.bottomHome {
  margin-left: auto;
  margin-right: auto;
}

.bottomHome nav{
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.longArrow {
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
}

.longArrow {
  transform: rotate(135deg);
}

.longArrow::after {
  content: "";
  display: block;
  width: 2px;
  height: 45px;
  background-color: black;
  transform: rotate(-45deg) translate(15px, 4px);
  left: 0;
  top: 0;
}

/* TRANSITION
transition: 0.2s;
.redirect:hover p{
  color: #3F3F3F;
}
*/

.redirect{
  transition: opacity .3s, transform .3s;
}
.bottomHome a {
  transition: 0.2s;
  text-decoration: none;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
}

.bottomHome li {
  margin-left: 5px;
  margin-right: 5px;
  display: inline;
}

.heroImage {
  max-width: 90vw;
}

.leftHome {
  /*height 100% means that the leftHome will fit at 100% of its grid area*/
  text-align: left;
  height: 100%;
  display: grid;
}

.mobileOnly {
  /*height 100% means that the leftHome will fit at 100% of its grid area*/
  text-align: left;
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
}

.underlineHeading {
  margin-top: 12px;
  width: 45px;
  height: 4px;
  background-color: #FDE07F;
}

.leftHome h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  font-size: 3rem;
  text-align: left;
  color: #E5E5E5;
}

.leftHome h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: left;
  color: #E5E5E5;
}

.leftHome p {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
}

.mobileOnly h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  color: #E5E5E5;
}

.mobileOnly h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  color: #E5E5E5;
}

.mobileOnly p {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
}

.leftHome nav {
  font-weight: normal;
  list-style-position: inside;
  color: #E5E5E5;
  font-size: 30px;
  text-decoration: none;
}

.leftHome nav a {
  color: #E5E5E5;
  text-decoration: none;
}

.dotSpace {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  background: #3F3D3D;
}

.sideButton {
  display: inline;
  text-align: center;
  transform: rotate(90deg);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #E5E5E5;
  text-align: center;
  transition: 0.2s;
  float: right;
}

.textContent p, h1, h2, h3, h4 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #E5E5E5;
}

.redirectClosed {
  transition: 0.2s;
  text-decoration: none;
  margin-bottom: 2.5rem;
  bottom: 0;
  right: 10px;
  border-radius: 90px;
  width: 30px;
  height: 30px;
  background-color: #faf3dd;
  position: fixed;
  cursor: pointer;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.redirectClosed p {
  
  transition: 0.2s;
  font-size: 20px;
  color: #0b0b0b;
}

.redirectClosed svg{
  transition: 0.2s;
  vertical-align: middle;
  margin-bottom: 1px;
}


.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-bottom: 3.5rem;
  margin-right: 0.5rem;
  bottom: 0;
  right: 10px;

  width: 50px;
  height: 50px;
 border-radius: 90px;
  background-color: #fde07f;
  position: fixed;
  cursor: pointer;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.redirect p {
  
  transition: 0.2s;
  font-size: 20px;
  color: #0b0b0b;
}

.redirect svg{
  transition: 0.2s;
  vertical-align: middle;
  margin-bottom: 1px;
}


.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p{

  color: #3F3F3F;
}


.circleNav {
  position: absolute;
  bottom: 0;
  align-items: center;
  margin-bottom: 0rem;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 25px;
  left: 0;
  border-radius: 20px 20px 0 0;
  background: #3a3a3a;
}

.circleNav svg {
  margin-top: 2px;
  font-size: 28px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
  font-weight: 500;

}

.sideHome {
  vertical-align: top;
}

.sideHome svg {
  vertical-align: bottom;
}

.bottomHome li {
  transition: 0.2s;
}

.menu li {
  transition: 0.2s;
}

.menu li a{
  transition: 0.2s;
}

.bottomHome li:hover a{
  color: #3F3F3F;
}

.menu li:hover {
  color: #3F3F3F;
}

.menu li:hover a{
  color: #3F3F3F;
}

.sideButton:hover {
  color: #3F3F3F;
}






/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */




@media (max-width: 699px) {
 
  .wrapper {
    margin: 0 auto;
    max-width: 699px;
    height: 100vh;
    grid-template-rows: 6rem auto 8rem 2rem;
  }
  /*wrapper Childs*/
  .leftHome {
    display: none;
  }
  .rightHome img {
    display: block;
    max-width: 90vw;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  h2{
    margin-top: 1rem;
  }

 /* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  .todo {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
  .wrapper {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

  .rightHome{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sideHome {
    display: none;
  }

  
  .bottomHome .li{
    color: #181818;
    font-size: 18px;
  }

  .bottomHome{
    bottom: 0px;
    width: 100%;
    margin-top: auto;
    position: absolute;
    position: fixed;
 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fde07f;
    z-index: 98;
    transition: top 0.2s, bottom 0.2s
  }

  

  .bottomHomeHide{
    width: 100%;
    bottom: -50px;
    height: 50px;
    margin-top: auto;
    position: absolute;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fde07f;
    z-index: 98;
    transition: top 0.2s, bottom 0.2s
  }

  .size{
    width: 100%;
    height: 100px;
  }

  .fitElements {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mobileOnly h1 {
    margin-top: 2rem;
  }
  .mobileOnly p {
    font-size: 20px;
    margin-top: 1rem;
  }
  .menu {
    display: none;
  }


  p{
    font-size: 16px;
  }

}


/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Tablet
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 700px) and (max-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    max-width: 1250px;
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 3fr 6fr 1fr;
  }
  /*wrapper Childs*/
  .topHome {
    margin-top: auto;
    margin-left: 4rem;
    margin-bottom: auto;
    grid-column: 1;
    grid-row: 1;
  }
  .rightHome img {
    display: block;
    max-width: 70vw;
    max-height: 50vh;
    min-height: 400px;
    margin-left: 4rem;
  }
  .rightHome {
    grid-column: 1;
    margin-top: auto;
    margin-bottom: auto;
  }
  .sideHome {
    margin-top: auto;
    margin-bottom: auto;
    grid-column: 3;
    float: right;
    grid-row: 2;
    white-space: nowrap;
  }
  .bottomHome {
    grid-column: 1/4;
    grid-row: 3;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .fitElements {
    margin-top: auto;
    margin-bottom: auto;
  }
  .rightHome {
    grid-row: 2;
  }
  .leftHome h2 {
    margin-top: 2rem;
  }
  .leftHome p {
    margin-top: 1rem;
  }
  .menu {
    display: none;
  }
  .mobileOnly {
    display: none;
  }
  .redirect{
    display: none;
  }

  @media(max-height: 720px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}

/*ss
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 8fr 3.5fr 0.5fr;
    grid-template-rows: 9fr 1fr;
  }
  /*wrapper Childs*/
  .topHome {
    grid-row: 1;
  }
  .rightHome {
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    grid-column: 2;
    grid-row: 1;
  }
  .rightHome img {
    max-width: 600px;
    margin-top: auto;
    margin-bottom: auto;
  }
  /* animation for image loop
  @keyframes rightHome{
    0% { max-width: 600px; }
    25% { max-width: 605px; }
    50% { max-width: 603px; }
    75% { max-width: 605px; }
    100% { max-width: 600px; }
  }

  .rightHome img {
  -webkit-animation:rightHome 4s linear infinite;
  -moz-animation: rightHome 4s linear infinite;
  -ms-animation: rightHome 4s linear infinite;
  -o-animation: rightHome 4s linear infinite;
  animation: rightHome 6s linear infinite;
  }*/
  .sideHome {
    margin-top: auto;
    margin-bottom: auto;
    grid-column: 3;
    float: right;
    white-space: nowrap;
  }
  .bottomHome {
    grid-column: 1/4;
    grid-row: 2;
  }
  .leftHome {
    grid-column: 1;
    grid-row: 1;
  }
  .fitElements {
    margin-top: auto;
    margin-bottom: auto;
  }
  .leftHome h1 {
    white-space: nowrap;
  }
  .leftHome h2 {
    margin-top: 2rem;
  }
  .leftHome p {
    margin-top: 1rem;
  }
  .leftHome nav {
    margin-top: 6rem;
  }
  .mobileOnly {
    display: none;
  }
  .mobileTablet {
    display: none;
  }

  .redirect{
    display: none;
  }

  @media(max-height: 560px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}
