a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3a;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  height: 100vh;
  margin: 0 10px 0;
  display: grid;
}

.bottomAbout {
  margin-left: auto;
  margin-right: auto;
}

.topAbout {
  margin-top: auto;
  margin-bottom: auto;
}

.bird img {
  float: right;
  margin-top: -30px;
  max-width: 150px;
}

.content {
  display: inline;
}

.leftAbout {
  margin-top: auto;
  margin-bottom: auto;
}

.leftAbout img {
  max-width: 350px;
  margin-top: 1rem;
}

h1{
  margin-top: 1rem;
}

.topAbout h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  font-size: 3rem;
  text-align: left;
  color: #E5E5E5;
}

.topAbout h2 {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
  margin-top: 2rem;
}

.bottomAbout h2 {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
}

/* TRANSITION
transition: 0.2s;
.redirect:hover p{
  color: #3F3F3F;
}
*/

.bottomAbout a {
  text-decoration: none;
  font-weight: normal;
  font-size: 24px;
  color: #929292;
}

.bottomAbout li {
  margin-left: 10px;
  margin-right: 10px;
  display: inline;
}

.heroImage {
  max-width: 90vw;
}


.bottomAbout li {
  transition: 0.2s;
}

.bottomAbout li:hover {
  color: #3F3F3F;
}

/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    grid-template-rows: 11fr 1fr;
  }
  /*wrapper Childs*/
  .topAbout {
    display: none;
  }
  .leftAbout {
    display: none;
  }

  .title{
    margin-left: 1rem;
  }
  .mobileOnly {
    margin-top: auto;
    margin-bottom: auto;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .mobileOnly h1 {
    font-size: 28px;
  
    margin-right: 1rem;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: #E5E5E5;
  }
  .mobileOnly h2 {
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #929292;
  }
  .button {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bottomAbout {
    display: none;
  }
  .bird {
    margin-top: auto;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .bird img {
    max-width: 100px;
  }

  @media(max-height: 590px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Tablet
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 700px) and (max-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 3.32fr 3.32fr 3.32fr;
    grid-template-rows: 8fr 2fr;
  }
  /*wrapper Childs*/
  .topAbout {
    margin-left: 4rem;
    margin-right: 4rem;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .leftAbout {
    margin-left: 4rem;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .leftAbout img {
    max-width: 300px;
  }
  .bottomAbout {
    grid-column-start: -4;
    grid-column-end: -2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 4rem;
  }
  .bird {
  
    margin-top: auto;
    grid-column-start: -2;
    grid-column-end: -1;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .bird img{
    max-width: 110px;
  }

  .mobileOnly{
    display: none;
  }

  @media(max-height: 690px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 3.32fr 3.32fr 3.32fr;
    grid-template-rows: 8fr 2fr;
  }
  /*wrapper Childs*/
  .topAbout {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .leftAbout {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .bottomAbout {
    margin-top: 2rem;
    grid-column-start: -4;
    grid-column-end: -2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .bird {
    margin-top: auto;
    grid-column-start: -2;
    grid-column-end: -1;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .mobileOnly{
    display: none;
  }

  @media(max-height: 640px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}
