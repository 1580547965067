a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  min-width: 100%;
  width: auto;
  min-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background: #181818;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  min-height: 100vh;
  height: auto;
  margin: 0 10px 0;
  display: grid;
  grid-template-rows: 10rem 18rem auto;

}

.header{
  grid-row: 1/2;
}

.status{
  grid-row: 2/3;
}

.products{
  grid-row: 3/4;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;

}



.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-top: 6rem;
  top: 0;
  right: 0;
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E5E5E5;
  position: fixed;
  cursor: pointer;
}

.content{
margin-top: 1rem;
}


.redirect p {
  transition: 0.2s;
  font-size: 18px;
  color: #3F3D3D;
}

.redirect svg {
  vertical-align: middle;
  margin-bottom: 1px;
}



.header {
  margin-top: 6rem;
}

.header h1 {
  font-size: 38px;
  text-align: left;
  margin-top: 0;
}

.search{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  background-color: #E5E5E5;
  border-radius: 5px;
  width: 130px;
  height: 50px;
}

.search p{
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #3F3D3D;
  font-weight: 500;
}

.search svg{

  vertical-align: middle;
}
.header h2 {
  font-size: 24px;
  margin-right: auto;
  margin-left: 0;
  text-align: left;
  font-weight: 300;
  color: #929292;
}

.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p {
  color: #3F3F3F;
}


/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .redirect {
    position: fixed;
    margin-top: 3.5rem;

  }

  .header {
    margin-top: 3.8rem;
  }

  
  .header h1{
    font-size:28px;
  }

  .header h2 {
    font-size: 18px;
    width: 80%;
  }

  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .todo {
    min-height: 100vh;
    height: auto;
    background: #181818;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    grid-template-rows: 6rem 18rem auto;
  
  }

  .search p{
    font-size: 18px;
  }
}

/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 700px) and (max-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .todo {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }
}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
  }


}