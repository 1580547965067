a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3a;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  height: 100vh;
  margin: 0 10px 0;
  display: grid;
  
}

.link{
  margin-top: -1rem;
  clear:both;
}

.target{
  margin-top: 4rem;
}
.link a{
  font-size: 1.3rem;
  text-decoration: none;
  font-weight: 300;
  color: #a4a4a4;

}

.app{
  margin-top: 0;
  width: 400px;
}

.link{
  margin-bottom: 4rem;
}

.button{
  margin-left: -1rem;
}

.left{
 display: block;


}

.cards{
  margin-top: 4rem;
}


.right p{
margin-top: 2rem;
margin-bottom: 2rem;
}

.right h1{
  text-align: left;
}





.underlineHeading {
  margin-top: 12px;
  width: 45px;
  height: 4px;
  background-color: #FECE2F;
}







/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
      }
      /*wrapper Childs*/
      .left {

        display: inline-block;
        margin-top: auto;
        margin-left: 1rem;
        margin-bottom: auto;
        grid-column: 1;
        grid-row: 0;
      }
      .right {
        margin-left: 1rem;
        margin-right: 1rem;
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        grid-column: 1;
        grid-row: 1;
     
      }
      .app{
        display: none;
      }

      .but{
        margin-left: -1rem;
      }

      .link a{
       
     
        font-size: 20px;
      }

      .right p{
        font-size: 20px;
        margin-bottom: 0;
      }

      h1{
        font-size: 28px;
      }

      h2{
        font-size: 24px;
      }
     
      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
      }
      /*wrapper Childs*/
      .left {
        margin-left: 4rem;
        margin-right: 4rem;
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        grid-column: 1;
        grid-row: 0;
      }
      .right {
        margin-left: 4rem;
        margin-right: 4rem;
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        grid-column: 1;
        grid-row: 1; 
    
      }

      .app{
        display: none;
      }

      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Desktop
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 6fr 4fr;
  
      }
      /*wrapper Childs*/
      .left {

        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        grid-column: 1/2;
        grid-row: 0;
      }
      .right {
        margin-top: auto;
        margin-bottom: auto;
        display: inline-block;
        grid-column: 2/3;
        grid-row: 0;
    
      }

      .app{
        margin-top: -20rem;
      }

      .content{
        width: 400px;
        margin-top: -4rem;
      }

      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }
    }
  