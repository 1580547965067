a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  min-width: 100%;
  width: auto;
  min-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #3a3a3a 550px, #181818 0%);
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  min-height: 100vh;
  height: auto;
  margin: 0 10px 0;
  display: grid;
}

.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-top: 6rem;
  top: 0;
  right: 0;
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E5E5E5;
  position: fixed;
  cursor: pointer;
}

.redirect p {
  transition: 0.2s;
  font-size: 18px;
  color: #3F3D3D;
}

.redirect svg{
  vertical-align: middle;
  margin-bottom: 1px;
}

.header{

margin-top: 5rem;
}

.header h1{
font-size: 38px;
text-align: left;
}

h1{
  font-size: 38px;
text-align: left;
}

.header h2{
  width: 600px;
  margin-left: 0;
  margin-top: 1rem;
  font-size: 18px;
  color: #acacac;
  text-align: left;
  font-weight: 300;
}

.header h3{
  font-weight: 300;
  font-size: 18px;
  margin-top: 1rem;
  text-align: left;
  font-weight: 300;
  color:  #fde07f;
}

.banner{
  border-radius: 5px;
  margin-top: 4rem;
  height: 400px;
  width: 100%;
  
}

.banner img{
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.text{
margin-top: 1.5rem;
}

.text pre {
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  width: auto;
}

.text p{
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 1rem;
  color: #acacac;
}

.text{
  white-space: 'pre-wrap';
}

.text blockquote{
  display: block;
  color: #5d5d5d;
  border-left: 8px solid #3F3F3F;
  display: grid;
  text-align: left;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0.8rem;
  max-width: 50%;
  background: #2a2a2a;
  grid-template-columns: auto 9fr;
  margin-bottom: 1rem;
}

.text code{
  color: #5d5d5d;
  border-left: 8px solid #3F3F3F;
  display: grid;
  text-align: left;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0.8rem;
  max-width: 50%;
  height: auto;
  background: #2a2a2a;
  grid-template-columns: auto 9fr;
  margin-bottom: 1rem;
}

.text code p{
  grid-column: 2;
  margin-bottom: 0;
  margin-left: 1rem;
}


.text blockquote p{
  grid-column: 2;
  margin-bottom: 0;
  margin-left: 1rem;
}

.text a{
  color:  #fde07f;
}

.text h1{
  font-weight: normal;
  text-align: left;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size:  28px;
  color:  #E5E5E5;
}

.text h2{
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-size: 28px;
  text-align: left;
  color:  #E5E5E5;
}

.text h3, h4, h5{
  text-align: left;
  color:  #E5E5E5;
}

.text img {
  border-radius: 5px;
}

.text gif{
  width: 400px;
}
.text video{
  width: 400px;
}

.text ul {  
  margin: 1rem;
  color: #acacac;
}

.text strong{
  font-weight: normal;
  color:  #E5E5E5;
}

.text ol {  
  margin: 1rem;
  color: #acacac;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.player{
  margin-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #181818;
  background: #fde07f;
  border-radius: 5px;
  box-shadow:0px 0px 0px 2px #fde07f inset;
  padding: 8px 14px 8px 14px;
  width: min-content;
  margin-top: 2rem;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;
}


.player:hover{
  color:#fde07f;
  background: #181818;
}



.player svg{
  font-size: 22px;
  vertical-align: middle;
  margin-right: 1px;
  margin-bottom: 2px;
}

.buttons{
  display: flex;
  flex-direction: row;
}

.footer{
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 1rem;
}

.footer p{
  font-size: 18px;
  margin-top: 1rem;
  color: #5d5d5d;
}

.footer a{
  font-size: 18px;
  text-decoration: none;
  color: #DADADA;
}


.complement img:nth-child(1){
  width: 600px;
}

.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p{

  color: #3F3F3F;
}


.textContent p, h1, h2, h3, h4{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #E5E5E5;
}







/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
        grid-template-rows: auto auto auto auto;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .redirect {
        transition: 0.2s;
        position: fixed;
        margin-top: 3.5rem;
 
      }



.text blockquote{
  max-width: 80%;
}

.text code{
 max-width: 80%;
}


      .banner{
        border-radius: 5px;
        margin-top: 4rem;
        width: 100%;
        height: 200px;
        background-color: #D9D9D9;
      }

      .header{
       margin-top: 2.8rem;
      }

      .header h1{
        font-size: 28px;
      }

      .header h2{
        width: 100%;
      }

      .banner img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .footer{
        margin-top: 4rem;
      }

      .text img{
        max-width: 100%;
        max-height: 200px;
      }

      .todo{
        min-height: 100vh;
        height: auto;
        background: linear-gradient(to bottom, #3a3a3a 480px, #181818 0%);
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }

  
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
        grid-template-rows: auto auto auto auto;
        margin-left: 4rem;
        margin-right: 4rem;
      }

      .header h2{
        width: 80%;
      }

      .banner{
        border-radius: 5px;
        margin-top: 4rem;
        width: auto;
        max-height: 300px;
        background-color: #D9D9D9;
      }

      .banner img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .text img{
        max-height: 200px;
      }

      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }
    }
    
/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 1250px) {
    .wrapper {
      margin: 0 auto;
      grid-template-columns: 12fr;
      grid-template-rows: auto auto auto auto;
    }
    /*wrapper Childs*/
    .topAbout {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .leftAbout {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .bottomAbout {
      margin-top: 2rem;
      grid-column-start: -4;
      grid-column-end: -2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .bird {
      margin-top: auto;
      grid-column-start: -2;
      grid-column-end: -1;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  
    .mobileOnly{
      display: none;
    }

    .text{
      width: 80%;
      margin-right: auto;
    }
  
  }
  