a {
  text-decoration: none;
}


.todo {
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #181818;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  height: 100vh;
  margin: 0 10px 0;
  display: grid;
}

.leftContact{
  margin-top: auto;
  margin-bottom: 5rem;
}



.rightContact{
  margin-top: auto;
  margin-bottom: 2rem;
  margin-left: auto;
}

.icons{
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.talkBottom p{
  margin-top: 0.5rem;
  font-size: 1rem;
}

.tag p{
  font-size: 1rem;
}

.circle{
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  line-height: 50%;
  width: min-content;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;
  margin-right: 8px;
  cursor: pointer;
}

.circle svg{
  color: #000;
  padding: 4px;
}



.leftContact h1{
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  text-align: left;
  color: #E5E5E5;
}

.leftContact p{
  margin-top: 32px;
}


.info a {
  text-decoration: none;
  font-weight: normal;
  font-size: 24px;
  color: #E5E5E5;
}

.info svg{
  margin-right: 1.5rem;
}

.telephone img{
  display: none;
  max-width: 500px;
}

.cardConnection {
  display: grid;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #E5E5E5;
  background: #3F3F3F;
  border-radius: 5px;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  width: min-content;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;
  margin-bottom: 1rem;
}

.info{
  margin-top: 4rem;
}

.connectionLeft{
  padding-right: 2rem;
}


.connectionLeft h2{
  text-align: left;
}

.space{
  padding-top: 10px;
}

.space2{
  display: none;
}


.infop {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #E5E5E5;
  border-radius: 5px;
  padding: 12px 18px 12px 0px;
  width: min-content;
  margin-top: 1rem;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;
}

.infop a{
  transition: 0.2s;
}

.infop a:hover{
  color: #3F3F3F;
}


.infop svg {
  vertical-align: top;
}

.cardTalk{
  display: grid;
}
.button {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #E5E5E5;
  background: #000;
  border-radius: 5px;
  padding: 12px 18px 12px 18px;
  width: min-content;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.2s;

  margin-left: 1rem;

}

.button:hover{
  background-color: #929292;
}


.circle:hover{
  background-color: #2d2d2d;
}

.circle:hover svg{
  color: #E5E5E5;
}

.button svg {
  vertical-align: top;
}

.talkTop{

  display: block;
  margin-right: auto;
}


.talkBottom{
  margin-right: auto;
}


h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  font-size: 3rem;
  text-align: left;
  color: #E5E5E5;
  margin-top: 1rem;
}

h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: left;
  color: #E5E5E5;
}

p {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #b5b4b4;
}

/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */



@media (max-width: 699px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 12fr;
    grid-template-rows: auto;
  }
  /*wrapper Childs*/
  .underlineHeading {
    margin-bottom: 2rem;
  }

  .connectionLeft{
    display: none;
  }

  .cardConnection{
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .leftContact{
    grid-row-start: 1;
    grid-row-end: 2;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .leftContact h1{
    font-size: 28px;
  }

  .leftContact p{
    font-size:20px;
  }

  .info{
    margin-top: 2rem;
  }

  .info svg{
    margin-right: 0.8rem;
  }

  .infop{
    margin-top: 0;
  }

  .infop{
    font-size: 20px;
  }
 .infop a{
  font-size: 20px;
 }

  .rightContact{
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .leftOne{
    grid-row-start: 3;
    grid-row-end: 4;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .leftOne h2{
    font-size: 24px;
  }

  .rightOne{
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .cardConnection{
    margin-right: auto;
    grid-template-columns: 4fr 5fr;
  }

  .connectionLeft{
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .connectionRight{
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .talkTop{
    margin-right: auto;
    grid-row-start: 1;
    grid-row-end: 2;
  }



  .space2{
    padding-top: 10px;
    display: block;
  }

  .talkBottom{
    grid-row-start: 2;
    grid-row-end: 3;
    display: none;
  }


  .talkTop{
    display: block;
    margin-right: auto;
  }

  .button{
    padding-right: auto;
  }

  @media(max-height: 518px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }

}

/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Tablet
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 700px) and (max-width: 1250px) {

  .wrapper {
    margin-left: 4rem;
    margin-right: 4rem;
    grid-template-columns: 5fr 5fr;
    grid-template-rows: auto;
  }

  .icons{
    margin-top: 1.5rem;
  }


  .talkTop{
    margin-left: 0;
    margin-right: auto;

  }

  .talkBottom{
    display: none;
    margin-left: 0;
    margin-right: auto;

  }

  .button{
    margin-left: 0;
  }

  .space2{
    padding-top: 10px;
    display: block;
  }

  @media(max-height: 660px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }
}

/*ss
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 5fr 5fr;
    grid-template-rows: auto;
  }

  .telephone img{
    margin-top: 1rem;
  }

  /*top Childs*/

  .leftContact{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .rightContact{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .telephone img{
    display:block
  }

  /**/

  .leftOne{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .leftOne{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .cardConnection{
    grid-template-columns: 4fr 5fr;
  }

  .connectionLeft{
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .connectionRight{
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .cardTalk{
    grid-template-rows: 7fr 3fr;
  }

  .talkTop{
    margin-right: 0;
    margin-left: auto;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .talkBottom{
    margin-right: 0;
    margin-left: auto;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .space2{
    display: block;
    margin-left: 1rem;
    display: inline-block;
  }

  @media(max-height: 620px){
    .todo{
      height: auto;
    }

    .wrapper{
      height: auto;
    }
  }

}
