
.underlineHeading {
  margin-top: 12px;
  width: 45px;
  height: 4px;
  background-color: #FDE07F;
}

@media (max-width: 699px) {
.title h1{
  font-size: 28px;
}
}