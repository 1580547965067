a {
  text-decoration: none;
}

.todo {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #181818;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  height: 100vh;
  margin: 0 10px 0;
  display: grid;

}

.link {
  margin-top: 1rem;
  clear: both;
}

.link a {
  font-size: 1.3rem;
  text-decoration: none;
  font-weight: 300;
  color: #f5f5f5;

}

.timeline {
  margin-bottom: auto;
  margin-top: auto;

}

.target {
  margin-top: 4rem;
}

.item {
  margin-top: -2rem;
}

.item {
  margin-bottom: 4rem;
}

.item h1 {
  font-size: 24px;
  text-align: right;
}

.item h2 {
  font-size: 24px;
  text-align: right;
}

.item h3 {
  color: #929292;
  font-size: 24px;
  text-align: right;
  font-weight: 300;
}

.link {
  margin-bottom: 2rem;
}

.button {
  margin-left: -1rem;
}

.left {
  display: block;
}

.left h1 {
  text-align: left;
}

.left p {
  margin-top: 2rem;
}

.cards {
  margin-top: 4rem;
}


.right p {

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.item {
  margin-right: 2rem;
}

.right {
  display: grid;
  grid-template-rows: 2fr 5fr 2fr;
  align-items: right;


}

.justline {
  display: grid;
  grid-template-columns: 10fr 0.2fr;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  margin-bottom: 0;
  grid-row-start: 3;
  grid-row-end: 4;
}

.dot {
  margin-top: 100%;
  margin-bottom: 6.5rem;
  position: relative;
  right: 7px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #FDE07F;
  z-index: 4;
}

.timeline {

  display: grid;
  grid-template-columns: 10fr 0.2fr;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  margin-bottom: 0;

}

.a {

  grid-column-start: 1;
  grid-column-end: 1;
}

.line {
  margin-right: 100%;
  height: 100%;
  width: 2px;
  background-color: #FDE07F;
}

.b {

  width: 15px;
  grid-column-start: 2;
  grid-column-end: 3;
}




/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */


@media (max-width: 699px) {
  .wrapper {
    margin: 0 auto;
    grid-template-rows: auto;
  }

  .leftContact h1{
    font-size: 28px;
  }

  .left p {
    font-size: 20px;
  }


  .item h1 {
    font-size: 20px;
  }

  .item h2 {
    font-size: 20px;
  }

  .item h3 {

    font-size: 20px;


  }

  .link a {
    font-size: 20px;
  }

  /*wrapper Childs*/
  .right {
    grid-template-rows: 0fr 5fr 0fr;
    display: grid;
    grid-row: 2/3;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 4rem;

  }

  .left {
    max-width: 100%;
    margin-right: 1rem;
    margin-left: 1rem;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    grid-row: 1/2;


  }



  .todo {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }

  @media(max-height: 590px) {
    .todo {
      height: auto;
    }

    .wrapper {
      height: auto;
    }
  }
}

/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 700px) and (max-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-rows: auto;
  }

  /*wrapper Childs*/
  .right {
    grid-template-rows: 0fr 5fr 0fr;
    display: grid;
    grid-row: 2/3;
    margin-right: 4rem;

  }



  .left {
    max-width: 100%;
    margin-right: 4rem;
    margin-left: 4rem;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    grid-row: 1/2;


  }

  .todo {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }


  @media(max-height: 690px) {
    .todo {
      height: auto;
    }

    .wrapper {
      height: auto;
    }
  }
}


/*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Desktop
      -------------------------------------------------------------------------------------------------------------
      */

@media (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    grid-template-columns: 5fr 5fr;
  }

  /*wrapper Childs*/
  .right {
    display: grid;

    grid-column: 2/3;
    grid-row: 0;

  }



  .left {

    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    grid-row: 0;
    grid-column: 1/2;

    max-width: 300px;

  }

  .todo {
    min-height: 100vh;
    height: auto;
  }

  .wrapper {
    min-height: 100vh;
    height: auto;
  }
}