.todo {
  font-weight: lighter;
  z-index: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3a;
}

.notFound img{
  max-width: 40vw;
}


.wrapper {
  z-index: 1;
  width: auto;
  margin: 1rem auto;
  display: grid;
}


.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-top: 6rem;
  top: 0;
  right: 0;
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E5E5E5;
  position: fixed;
  cursor: pointer;
}

.redirect p {
  transition: 0.2s;
  font-size: 18px;
  color: #3F3D3D;
}

.redirect svg{
  vertical-align: middle;
  margin-bottom: 1px;
}

.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p{

  color: #3F3F3F;
}



.textContent p, h1, h2, h3, h4{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #E5E5E5;
}


/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    @media (max-width: 699px) {
      .notFound img{
        max-width: 80vw;
      }
  }
  /*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Tablet
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 700px) and (max-width: 1199px) {
    .notFound img{
      max-width: 50vw;
    }
  }
  /*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 1200px) {
    .notFound img{
      max-width: 30vw;
    }
  }
