a {
  text-decoration: none;
}

.todo {
  overflow: hidden;
  z-index: 0;
  min-width: 100%;
  width: auto;
  min-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  background: #181818;
}

.wrapper {
  z-index: 1;
  max-width: 1200px;
  min-height: 100vh;
  height: auto;
  margin: 0 10px 0;
  display: grid;
}

.redirect {
  transition: 0.2s;
  text-decoration: none;
  margin-top: 6rem;
  top: 0;
  right: 0;
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #E5E5E5;
  position: fixed;
  cursor: pointer;
}

.redirect p {
  transition: 0.2s;
  font-size: 18px;
  color: #3F3D3D;
}

.redirect svg{
  vertical-align: middle;
  margin-bottom: 1px;
}

.header{
margin-top: 5rem;
}

.header h1{
font-size: 38px;
text-align: left;
}

h1{
font-size: 38px;
text-align: left;
}


.bannerB{
  cursor: pointer;
  transition: 0.2s;
  display: grid;
  grid-template-columns: 6fr 4fr;
  border-radius: 5px;
  margin-top: 4rem;
  height: 400px;
  width: 100%;
  background-color: #646464;
}


.bannerB .info p{
  color: #b9b9b9;
}


.bannerB img{
  grid-column: 1/2;
  transition: 0.2s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 0 0 5px;
}


.banner{
  cursor: pointer;
  transition: 0.2s;
  display: grid;
  grid-template-columns: 6fr 4fr;
  border-radius: 5px;
  margin-top: 4rem;
  height: 400px;
  width: 100%;
  background-color: #3F3F3F;
}

.banner:hover{
  background-color: #313131;
}



.bannerB:hover{
  background-color: #5f5f5f;
}

.banner img{
  grid-column: 1/2;
  transition: 0.2s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 0 0 5px;
}

.info h1{
  margin-left: 0;
  display: block;
  grid-row: 2/3;
  font-weight: 400;
  margin-top: auto;
  color: #E5E5E5;
  text-align: right;
  font-size: 32px;
}

.h3{
  margin-left: 0;
  display: block;
  grid-row: 1/2;
  font-weight: 400;
  color: #fde07f;
  text-align: right;
  font-size: 24px;
  margin-top: 1rem;
  margin-right: auto;
}

.info p{
  display: block;
  grid-row: 3/4;
  text-align: left;
  font-size: 24px;
  color: #777673;
  margin-bottom: 2rem;
  font-weight: 300;
  margin-top: auto;
}

.info{
  display: grid;
  padding: 2rem;
  grid-template-rows: auto auto auto;
  grid-column: 2/3;
}

.posts{
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  row-gap: 1.5rem;
}

.footer{
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 1rem;
}

.footer p{
  font-size: 18px;
  margin-top: 1rem;
  color: #3F3F3F;
}

.footer a{
  font-size: 18px;
  text-decoration: none;
  color: #DADADA;
}




.complement img:nth-child(1){
  width: 600px;
}

.redirect:hover {
  background-color: #fde07f;
}

.redirect:hover p{

  color: #3F3F3F;
}







/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
        grid-template-rows: auto auto auto auto;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .info{
        grid-row: 2/3;
        grid-column: 1/3;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      .redirect {
        position: fixed;
        margin-top: 3.5rem;
 
      }
      
      .info h1{
        margin-top: auto;
        margin-bottom: auto;
        margin-right: auto;
        margin-left: 0;
        font-size: 24px;
      }
      
      .h3{
        margin-top: auto;

        margin-right: auto;
        margin-left: 0;
        font-size: 24px;
      }
      
      .info p{
        margin-bottom: auto;
        margin-top: 0.6rem;
        text-align: left;
        margin-right: auto;
        margin-left: 0;
        font-size: 18px;
      }

      .bannerB{
        grid-template-rows: 3.5fr 5fr;
        height: auto;
      }

      .banner{
        grid-template-rows: 3.5fr 5fr;
        height: auto;
      }


      .bannerB img{
        grid-row: 1/2;
        grid-column: 1/3;
        height: 100%;
        height: 220px;
        border-radius: 5px 5px 0px 0px;
      }

      .banner img{
        grid-row: 1/2;
        grid-column: 1/3;
        height: 100%;
        height: 220px;
        border-radius: 5px 5px 0px 0px;
      }
    
      .posts{
        margin-top: 4rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1rem;
      }

   

      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }

      .header{
        margin-top: 2.8rem;
      }
      
      .header h1{
        font-size:28px;
      }
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
      .wrapper {
        margin: 0 auto;
        grid-template-columns: 12fr;
        grid-template-rows: auto auto auto auto;
        margin-left: 4rem;
        margin-right: 4rem;
      }

      .banner:hover img{
        width: 95%;
      }
      
      .bannerB:hover img{
        width: 95%;
      }

      .info{
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      .info h1{
        margin-top: auto;
        margin-bottom: auto;
        margin-right: auto;
        margin-left: 0;
        font-size: 24px;
      }
      
      .h3{
        margin-top: auto;

        margin-right: auto;
        margin-left: 0;
        font-size: 24px;
      }
      
      .info p{
        margin-bottom: auto;
  
        text-align: left;
        margin-right: auto;
        margin-left: 0;
        font-size: 18px;
   
      }

      .posts{
        margin-top: 4rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 3rem;
        row-gap: 1.5rem;
      }

      .todo{
        min-height: 100vh;
        height: auto;
      }
  
      .wrapper{
        min-height: 100vh;
        height: auto;
      }
    }
    
/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 1250px) {
    .wrapper {
      margin: 0 auto;
      grid-template-columns: 12fr;
      grid-template-rows: auto auto auto auto auto;
    }

    .banner:hover img{
      width: 95%;
    }
    
    .bannerB:hover img{
      width: 95%;
    }

  
  }
  