*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card{
  border-radius: 5px;
  width: 36rem;
  padding-right: 2rem;
  height: auto;
  float: left;
  padding: 2rem;
  background-color: #3F3F3F;
  transition: 0.2s;
}



.card a {
  color: #D9D9D9;
}

.card svg{
  margin-bottom: -2px;
}

.card h1{
  color: #D9D9D9;
  margin-top: 0;
  font-weight: 500;
  margin-left: 0;
  text-align: left;
  font-size: 24px;
}

.card h2{
  margin-top: 0.2rem;
  font-weight: 400;
  margin-left: 0;
  grid-row: 2/3;
  text-align: left;
  font-size: 18px;
  color: #FDE07F;
}

.card p{
  font-weight: 300;

  text-align: left;
  color: #C1C1C1;
  margin-top: 0.8rem;
  font-size: 18px;
 
}
.full{
  margin-top: 2rem;
}

.order h3 {
  color: #3F3D3D;
  text-align: left;
  font-size: 24px;
  margin-bottom: 0.5rem;
}

.card h3{
  margin-left: 0;
  font-weight: 450;
  margin-top: 0.8rem;
  text-align: left;
  font-size: 18px;
  color: #929292;
}




/*
    -------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------
    Mobile
    -------------------------------------------------------------------------------------------------------------
    */

    
    @media (max-width: 699px) {
      .card{
        padding: 2rem;
        max-width: 30rem;
        width: auto;
        height: auto;
       }

       .card h1{
        font-size: 18px;
       }
       .card h2{
        font-size: 12px;
       }


       .order h3{
        font-size: 18px;
       }

       .card p{
        margin-top: 0.6rem;
        font-size: 12px;
       }
  
       .card h3{
        margin-top: 0.6rem;
        font-size: 12px;
       }

      
    }
    
    /*
      -------------------------------------------------------------------------------------------------------------
      -------------------------------------------------------------------------------------------------------------
      Tablet
      -------------------------------------------------------------------------------------------------------------
      */
    
    @media (min-width: 700px) and (max-width: 1250px) {
     .card{
      padding: 2rem;
      max-width: 35rem;
      width: 100%;
     }

  

    }
    
/*
  -------------------------------------------------------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------
  Desktop
  -------------------------------------------------------------------------------------------------------------
  */

  @media (min-width: 1250px) {
    .card{
      padding: 2rem;
      max-width: 35rem;
      width: auto;
     }
  }
  